.App {
  text-align: center;
}

/* Import fonts once */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background-color: #1a2b49;
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  padding: 0;
}

/* Landing page styles */
.landing-page {
  background-color: #1a2b49;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.landing-logo {
  width: 100px;
  margin-bottom: 1rem;
}

.landing-page h1 {
  margin-bottom: 1rem;
  font-size: 30px;
  text-transform: uppercase;
  color: #dfdada;
  letter-spacing: 6px;
}

.landing-page p {
  margin-bottom: 1.5rem;
  color: #dfdada;
}

/* Original container for the integrated input/button */
/* (This layout places the button inside the input field container via absolute positioning) */
.landing-input-container {
  position: relative;
  width: 300px;      /* Set your desired width */
  max-width: 90%;
  margin-bottom: 1rem;
}

/* Original email input field styling */
.landing-input {
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 0.75rem; /* extra right padding for the button */
  font-size: 1rem;
  border-radius: 30px 0 0 30px; /* rounded on left side */
  border: none;
  box-sizing: border-box;
}

/* Original submit button attached to the input */

/* Original opt-in container styling */
.optin-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  max-width: 90%;
}

/* Original checkbox styling */
.optin-checkbox {
  margin-right: 0.5rem;
}

/* Docuseal form buttons styling */
.type-text-button,
.upload-image-button,
.clear-canvas-button,
.set-current-date-button,
.reupload-button {
  background-color: #1a2b49;
  border: 0;
  border-radius: 0.25rem;
  color: #ffffff;
}

/* ============================================================= */
/* New styles for a stacked layout where the button appears below the input and above the opt-in message */
/* To use this layout, wrap your elements in a container with the class "landing-form-stacked" */
/* ============================================================= */
.landing-form-stacked {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;  /* or your desired width */
  margin-top: 1rem;
}

.landing-form-stacked .landing-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 30px;
  border: none;
  box-sizing: border-box;
  margin-bottom: 1rem;
  height: 50px;
}

.landing-form-stacked .landing-button {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background-color: #ffb800;
  color: #1a2b49;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1rem;
}

.landing-form-stacked .optin-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

label{
  font-size: 12px;
  text-align: left;
}